export const ExportToExcel = (arrayList, excludeFields) => {
    if (!arrayList || arrayList.length === 0) {
      return; // No data to export
    }
  
    // Helper function to flatten objects
    const flattenObject = (obj, parent = '', res = {}) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const propName = parent ? `${parent}_${key}` : key;
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            flattenObject(obj[key], propName, res);
          } else {
            res[propName] = obj[key];
          }
        }
      }
      return res;
    };
  
    // Flatten all objects in the array
    const flatArrayList = arrayList.map(item => flattenObject(item));
  
    // Remove excluded fields
    const filteredArrayList = flatArrayList.map(row => {
      const filteredRow = { ...row };
      excludeFields.forEach(field => {
        delete filteredRow[field];
      });
      return filteredRow;
    });
  
    // Create the HTML table structure as a string
    let table = `<table border="1"><tr>`;
  
    // Get the keys (column headers) from the first object
    const headers = Object.keys(filteredArrayList[0]);
    headers.forEach(header => {
      table += `<th>${header}</th>`;
    });
    table += `</tr>`;
  
    // Add the data rows to the table
    filteredArrayList.forEach((row) => {
      table += `<tr>`;
      headers.forEach(header => {
        const cellValue = row[header] != null && row[header] !== '' ? row[header] : ''; // Skip 0 or undefined
        table += `<td>${cellValue}</td>`;
      });
      table += `</tr>`;
    });
  
    table += `</table>`;
  
    // Create a Blob with the correct MIME type for Excel
    const excelBlob = new Blob([table], {
      type: "application/vnd.ms-excel"
    });
  
    // Create a downloadable link for the Blob
    const link = document.createElement("a");
    const url = URL.createObjectURL(excelBlob);
    link.href = url;
    link.download = "data.xls"; // File name with .xls extension
  
    // Append the link to the document body and trigger the download
    document.body.appendChild(link);
    link.click();
  
    // Clean up by removing the link after download
    document.body.removeChild(link);
  };
  