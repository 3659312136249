import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Layout from "../component/Layout";
import moment from 'moment';
import { ExportToExcel } from "./utils/common-methods";

function RegistrationList() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages]=useState(1)
  const [registrationList, setRegistrationList] = useState([]);
  const [loading, setLoading] = useState(false);
  let [excelValue , setExcelValues]=useState([]);
  const navigate = useNavigate();

  const RegistrationData = async () => {
    let token = localStorage.getItem("token");
    const obj = {
      page: currentPage,
      limit: 8,
    };
    setLoading(true);
    axios
      .post(`${BASE_URL}admin/getAllRegistration`, obj, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          console.log("registration",response.data.data);

          setRegistrationList(response.data.data);
          setExcelValues(response.data.data.map((item) => ({
            "Apply_for": item.apply_for,
            "Name": item.name,
            "Event Name": item?.Event[0]?.name || "N/A",
            "Age": item.age,
            "Gender": item.gender,
            "Phone Number": item.mobile_no,
            "State": item.state,
            "Disctrict": item.district,
            "Town": item.town,
            "Jersey No":item.jerseyNo || "",
            "Jersey Name":item.jerseyName || "",
            "Jersey Size":item.jerseySize || "",
            "Photo": item.photo || "",
            "Resume": item.resume,
            "feeScreenShot":item.feeScreenShot || "",
            "createdAt":moment(item.createdAt).format("YYYY/MM/DD") || "",

          })))
          
          setPages(response.data.remainingCount);
          setLoading(false);
        } else {
          setLoading(false);
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };
  const deleteRegistration = async (id) => {
    let token = localStorage.getItem("token");
    if (!(window.confirm("Do you really want to delete this item?"))) {
        return;
      } 
    axios
      .delete(`${BASE_URL}admin/deleteRegistration/${id}`, {
        headers: {
          "Content-Type": "Application/json",
          "x-access-token": `${token}`,
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.sucess) {
          toast.error(response.data.message);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    RegistrationData();
  }, [currentPage]);

  return (
    <Layout>
      <div className="page-content mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Registration List</h4>
                <button className="btn btn-primary" onClick={()=>ExportToExcel(excelValue,[
                    ])}>Export to Excel</button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : registrationList?.length > 0 ? (
                    <table
                      id="datatable"
                      className="table table-bordered dt-responsive nowrap w-100"
                    >
                      <thead>
                        <tr>
                          <th>S. No</th>
                          <th>Image</th>
                          <th>Apply For </th>
                          <th>Name</th>
                          <th>Age</th>
                          <th>Contact Number</th>
                          <th>Created At</th>


                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {registrationList?.map((item, index) => (
                          <tr key={item._id}>
                            <td>{(currentPage - 1) * 8 + index + 1}</td>
                            <td>
                              <img
                                src={item.photo}
                                alt="Image"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                }}
                              />
                            </td>
                            <td>{item.apply_for}</td>
                            <td>{item.name}</td>

                            <td>{item.age}</td>
                            <td>{item.mobile_no}</td>
                            <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>


                            <td>
                              <div>
                                <div
                                  className="btn btn-outline-secondary btn-sm edit"
                                  title="Edit"
                                  onClick={() =>
                                    navigate("/registration-view", { state: { item } })
                                  }
                                >
                                  <i className="fas fa-eye" />
                                </div>
                                &nbsp;&nbsp;
                                <div
                                  className="btn btn-outline-secondary btn-sm edit"
                                  title="Edit"
                                  onClick={() => deleteRegistration(item._id)}
                                >
                                  <i className="fas fa-trash-alt" />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <h2>No Record Found </h2>
                    </div>
                  )}
                </div>
              </div>
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li
                    class={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                      <>
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      </>
                    )
                  )}
                  <li
                    class={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>{" "}
            {/* end col */}
          </div>{" "}
        </div>{" "}
        {/* container-fluid */}
      </div>
    </Layout>
  );
}

export default RegistrationList;
