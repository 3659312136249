import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

function Menu() {
  const navigate = useNavigate();
  const [openMenus, setOpenMenus] = useState({
    event: false,
    post: false,
    imageGallery: false
  });

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate("/login")
    }
  }, [])

  // Function to toggle specific submenu
  const toggleSubmenu = (menuKey) => {
    setOpenMenus(prevState => ({
      ...prevState,
      [menuKey]: !prevState[menuKey]
    }));
  }

  return (
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title" key="t-menu">Menu</li>
            
            <li onClick={() => navigate("/dashboard")}>
              <a href="javascript: void(0);" className="waves-effect">
                <i className="bx bx-home-circle" />
                <span key="t-dashboards">Dashboards</span>
              </a>
            </li>
            
            {/* Event Submenu */}
            <li className={openMenus.event ? 'mm-active' : ''}>
              <a 
                href="javascript: void(0);" 
                className="has-arrow waves-effect"
                onClick={() => toggleSubmenu('event')}
              >
                <i className="bx bx-store" />
                <span key="t-ecommerce">Event</span>
              </a>
              {openMenus.event && (
                <ul className="sub-menu1" aria-expanded="true">
                  <li><Link key="t-products" to="/add-event">Add Event</Link></li>
                  <li><Link key="t-product-detail" to="/event-list">Event List</Link></li>
                  <li><Link key="t-products" to="/add-edit-event-details">Add/Edit Event Details</Link></li>
                </ul>
              )}
            </li>
            
            {/* Post Submenu */}
            <li className={openMenus.post ? 'mm-active' : ''}>
              <a 
                href="javascript: void(0);" 
                className="has-arrow waves-effect"
                onClick={() => toggleSubmenu('post')}
              >
                <i className="bx bx-store" />
                <span key="t-ecommerce">Post</span>
              </a>
              {openMenus.post && (
                <ul className="sub-menu1" aria-expanded="true">
                  <li><Link key="t-products" to="/">New Post</Link></li>
                  <li><Link key="t-product-detail" to="/post-list">Post List</Link></li>
                </ul>
              )}
            </li>
            
            {/* Image Gallery Submenu */}
            <li className={openMenus.imageGallery ? 'mm-active' : ''}>
              <a 
                href="javascript: void(0);" 
                className="has-arrow waves-effect"
                onClick={() => toggleSubmenu('imageGallery')}
              >
                <i className="bx bx-store" />
                <span key="t-ecommerce">Image Gallery</span>
              </a>
              {openMenus.imageGallery && (
                <ul className="sub-menu1" aria-expanded="true">
                  <li><Link key="t-products" to="/add-image-category">Add Image Category</Link></li>
                  <li><Link key="t-products" to="/image-category-list">Image Category List</Link></li>
                  <li><Link key="t-products" to="/add-image">Add Image</Link></li>
                  <li><Link key="t-product-detail" to="/image-gallery-list">Image Gallery List</Link></li>
                  <li><Link key="t-products" to="/add-video">Add Video</Link></li>
                  <li><Link key="t-product-detail" to="/video-gallery-list">Video Gallery List</Link></li>
                </ul>
              )}
            </li>
            
            <li onClick={() => navigate("/registration-list")}>
              <a href="javascript: void(0);" className="waves-effect">
                <i className="bx bx-home-circle" />
                <span key="t-dashboards">Registration List</span>
              </a>
            </li>
            
            <li onClick={() => navigate("/contact-list")}>
              <a href="javascript: void(0);" className="waves-effect">
                <i className="bx bx-home-circle" />
                <span key="t-dashboards">Contact List</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Menu